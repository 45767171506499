<template>
  <v-container fluid class="pa-0">
    <v-data-table
      :loading="loading"
      :headers="patientHeaders"
      :items="myClients"
      disable-pagination
      disable-sort
      disable-filtering
      hide-default-footer
      class="elevation-0"
    >
      <template v-slot:item.users="{ item }">
        <v-layout flex-nowrap align-center>
          <UserAvatar :user="item" :size="40" />
          <span class="ms-2">{{ getUserName(item) }}</span>

          <v-menu bottom :offset-x="!$vuetify.rtl" elevation="0" dense outlined>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                class="ml-auto"
                icon
                dark
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list class="py-0" dense>
              <v-list-item @click="getUserProfileRoute(item)">
                <v-list-item-title>{{ $t("View Profile") }}</v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item
                @click="getUserClientFileRoute(item)"
                v-if="!adminToken || isChiefTherapist"
              >
                <v-list-item-title>{{ $t("Client File") }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="getUserJournalRoute(item)"
                v-if="!adminToken"
              >
                <v-list-item-title>{{ $t("Journal") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="getUserChatRoute(item)" v-if="!adminToken">
                <v-list-item-title>{{
                  $t("Send a Message")
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-layout>
      </template>

      <template v-slot:item.subscriptionPlan="{ item }">
        {{ $t(getUserSubscriptionPlan(item)) }}
      </template>

      <template v-slot:item.subscriptionEndDate="{ item }">
        {{ getUserSubscriptionEndDate(item) }}
      </template>

      <template v-slot:item.liveSessionCredits="{ item }">
        {{ getUserLiveSessionCredits(item) }}
      </template>

      <template v-slot:item.nextSession="{ item }">
        <p class="subitile-2 font-weight-medium mb-0 font-size-12">
          {{ $t(getUserNextSession(item)) }}
        </p>
      </template>

      <template v-slot:item.disorders="{ item }">
        <v-chip-group dark color="primary" dense column>
          <template v-for="disorder in getUserDisorders(item)">
            <v-chip
              class="me-2 mb-1"
              small
              color="accent"
              outlined
              :key="disorder.name"
            >
              <b>{{ $t(disorder.name) }}</b>
            </v-chip>
          </template>
        </v-chip-group>
        <v-chip v-if="getUserDisordersOther(item)" class="me-2 mb-2" small>
          <b>{{ getUserDisordersOther(item) }}</b>
        </v-chip>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import UserMixin from "@/mixins/UserMixin";
import PatientMixin from "@/mixins/PatientMixin";
import UserAvatar from "@/components/UserAvatar";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Patients",

  mixins: [UserMixin, PatientMixin],

  components: {
    UserAvatar,
  },

  data: () => ({
    items: [],

    loading: false,
  }),

  computed: {
    ...mapGetters("calendar", ["events"]),
    ...mapGetters("user", ["clients"]),

    patientHeaders() {
      return [
        { text: this.$t("Clients"), value: "users" },
        { text: this.$t("Subscription Plan"), value: "subscriptionPlan" },
        {
          text: this.$t("Subscription end date"),
          value: "subscriptionEndDate",
        },
        { text: this.$t("Live Session Credits"), value: "liveSessionCredits" },
        { text: this.$t("Next Session"), value: "nextSession" },
        { text: this.$t("Disorders"), value: "disorders", width: "50%" },
      ];
    },

    _bookedEvents() {
      return (
        this.events.filter((e) =>
          ["booked", "bookedFree"].includes(e.status)
        ) || []
      );
    },
  },

  methods: {
    ...mapActions("calendar", ["getEvents"]),

    // ...mapActions("user", ['getClientsAndSubscriptions']),
  },
  async mounted() {
    this.awaitPromiseCallback({
      key: "getMe",
      cb: async () => {
        this.loading = true;
        await Promise.all([this.getClients(), this.getEvents()]);
        this.loading = false;
      },
    });
  },
};
</script>
