var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"loading":_vm.loading,"headers":_vm.patientHeaders,"items":_vm.myClients,"disable-pagination":"","disable-sort":"","disable-filtering":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.users",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{attrs:{"flex-nowrap":"","align-center":""}},[_c('UserAvatar',{attrs:{"user":item,"size":40}}),_c('span',{staticClass:"ms-2"},[_vm._v(_vm._s(_vm.getUserName(item)))]),_c('v-menu',{attrs:{"bottom":"","offset-x":!_vm.$vuetify.rtl,"elevation":"0","dense":"","outlined":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-auto",attrs:{"color":"primary","icon":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"py-0",attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.getUserProfileRoute(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("View Profile")))])],1),_c('v-divider'),(!_vm.adminToken || _vm.isChiefTherapist)?_c('v-list-item',{on:{"click":function($event){return _vm.getUserClientFileRoute(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Client File")))])],1):_vm._e(),(!_vm.adminToken)?_c('v-list-item',{on:{"click":function($event){return _vm.getUserJournalRoute(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Journal")))])],1):_vm._e(),(!_vm.adminToken)?_c('v-list-item',{on:{"click":function($event){return _vm.getUserChatRoute(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Send a Message")))])],1):_vm._e()],1)],1)],1)]}},{key:"item.subscriptionPlan",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(_vm.getUserSubscriptionPlan(item)))+" ")]}},{key:"item.subscriptionEndDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUserSubscriptionEndDate(item))+" ")]}},{key:"item.liveSessionCredits",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUserLiveSessionCredits(item))+" ")]}},{key:"item.nextSession",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"subitile-2 font-weight-medium mb-0 font-size-12"},[_vm._v(" "+_vm._s(_vm.$t(_vm.getUserNextSession(item)))+" ")])]}},{key:"item.disorders",fn:function(ref){
var item = ref.item;
return [_c('v-chip-group',{attrs:{"dark":"","color":"primary","dense":"","column":""}},[_vm._l((_vm.getUserDisorders(item)),function(disorder){return [_c('v-chip',{key:disorder.name,staticClass:"me-2 mb-1",attrs:{"small":"","color":"accent","outlined":""}},[_c('b',[_vm._v(_vm._s(_vm.$t(disorder.name)))])])]})],2),(_vm.getUserDisordersOther(item))?_c('v-chip',{staticClass:"me-2 mb-2",attrs:{"small":""}},[_c('b',[_vm._v(_vm._s(_vm.getUserDisordersOther(item)))])]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }